import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { FaCompass } from 'react-icons/fa';
import { ThemeContext } from '../context/ThemeContext';

const Loader = () => {
  const { isDarkMode } = useContext(ThemeContext);
  
  const logoVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeInOut"
      }
    }
  };

  const loadingCircleVariants = {
    start: {
      y: "0%"
    },
    end: {
      y: "100%"
    }
  };

  const loadingCircleTransition = {
    duration: 0.5,
    yoyo: Infinity,
    ease: "easeInOut"
  };

  const loadingContainerVariants = {
    start: {
      transition: {
        staggerChildren: 0.1
      }
    },
    end: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const loaderBgClass = isDarkMode ? 'bg-black' : 'bg-white';

  return (
    <motion.div className={`fixed inset-0 flex flex-col items-center justify-center z-50 ${loaderBgClass}`}>
      <div className={`text-5xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
        AB Consultancy
      </div>
      <FaCompass className="text-yellow-400 text-4xl mt-4" />
      <motion.p className="mt-8 text-xl" style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
        Crafting Future Leaders & Organizations
      </motion.p>
    </motion.div>
  );
};

export default Loader; 