import React, { useEffect, useRef, useContext } from 'react';
import { motion } from 'framer-motion';
import { gsap } from 'gsap';
import { FaCompass } from 'react-icons/fa';
import { ThemeContext } from '../context/ThemeContext';

const Hero = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const backgroundRef = useRef(null);
  const movingElementsRef = useRef(null);
  const compassElementsRef = useRef(null);
  
  // Animation variants for the compass icon
  const compassIconVariants = {
    initial: { rotate: 0 },
    animate: {
      rotate: [0, -15, 20, -25, 15, -5, 0, 10, -10, 5, 0],
      transition: {
        duration: 4,
        ease: "easeInOut",
        times: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        repeat: Infinity,
        repeatDelay: 3,
      }
    }
  };
  
  useEffect(() => {
    // Create a subtle floating animation for the background elements
    if (backgroundRef.current) {
      const elements = backgroundRef.current.querySelectorAll('.floating-element');
      elements.forEach((el) => {
        gsap.to(el, {
          y: 'random(-20, 20)',
          x: 'random(-20, 20)',
          rotation: 'random(-10, 10)',
          duration: 'random(3, 6)',
          repeat: -1,
          yoyo: true,
          ease: 'sine.inOut',
          delay: 'random(0, 2)',
        });
      });
    }

    // Create slow-moving elements across the background (only visible in light mode)
    if (movingElementsRef.current && !isDarkMode) {
      const movingElements = movingElementsRef.current.querySelectorAll('.moving-element');
      
      // Clear any existing animations
      movingElements.forEach(el => {
        gsap.killTweensOf(el);
      });
      
      movingElements.forEach((el) => {
        const direction = Math.random() > 0.5 ? 1 : -1;
        const startX = direction > 0 ? -200 : window.innerWidth + 200;
        const endX = direction > 0 ? window.innerWidth + 200 : -200;
        
        gsap.fromTo(el, 
          { 
            x: startX, 
            y: Math.random() * window.innerHeight, 
            opacity: 0,
            scale: 0.8
          },
          {
            x: endX,
            opacity: 0.7,
            scale: 1,
            duration: Math.random() * 40 + 30, // Between 30-70 seconds to cross (faster than before)
            ease: 'none',
            repeat: -1,
            delay: Math.random() * 10,
            onRepeat: () => {
              gsap.set(el, { 
                y: Math.random() * window.innerHeight,
                scale: 0.8 + Math.random() * 0.4
              });
            }
          }
        );
      });
    }

    // Animate compass-inspired elements
    if (compassElementsRef.current) {
      const compassRoses = compassElementsRef.current.querySelectorAll('.compass-rose');
      const compassCircles = compassElementsRef.current.querySelectorAll('.compass-circle');
      const compassMarkers = compassElementsRef.current.querySelectorAll('.compass-marker');

      // Animate compass roses with rotation
      compassRoses.forEach((el) => {
        gsap.to(el, {
          rotation: 'random(180, 360)',
          duration: 'random(60, 120)',
          repeat: -1,
          ease: 'none',
          delay: 'random(0, 5)',
        });
      });

      // Animate compass circles with pulsing effect
      compassCircles.forEach((el) => {
        gsap.to(el, {
          scale: 'random(0.9, 1.1)',
          opacity: 'random(0.4, 0.7)',
          duration: 'random(4, 8)',
          repeat: -1,
          yoyo: true,
          ease: 'sine.inOut',
          delay: 'random(0, 3)',
        });
      });

      // Animate compass markers with movement
      compassMarkers.forEach((el) => {
        gsap.to(el, {
          y: 'random(-30, 30)',
          x: 'random(-30, 30)',
          rotation: 'random(-15, 15)',
          duration: 'random(5, 10)',
          repeat: -1,
          yoyo: true,
          ease: 'sine.inOut',
          delay: 'random(0, 4)',
        });
      });
    }
  }, [isDarkMode]);

  const titleVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const subtitleVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        delay: 0.3,
      },
    },
  };

  const buttonVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: 0.6,
      },
    },
    hover: {
      scale: 1.03,
      boxShadow: "0px 0px 20px rgba(59, 130, 246, 0.4)",
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };

  const statsVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: 0.8 + (custom * 0.1),
      },
    }),
  };

  return (
    <section id="home" className="relative min-h-screen flex items-center overflow-hidden pt-20">
      {/* Animated background elements */}
      <div ref={backgroundRef} className="absolute inset-0 overflow-hidden">
        <div className="floating-element absolute top-1/4 left-1/5 w-24 h-24 rounded-full bg-gray-200/10 blur-xl"></div>
        <div className="floating-element absolute top-2/3 left-1/3 w-40 h-40 rounded-full bg-gray-300/10 blur-xl"></div>
        <div className="floating-element absolute top-1/2 right-1/4 w-32 h-32 rounded-full bg-gray-200/10 blur-xl"></div>
        <div className="floating-element absolute bottom-1/4 right-1/3 w-28 h-28 rounded-full bg-gray-300/10 blur-xl"></div>
        <div className="floating-element absolute top-1/3 right-1/5 w-36 h-36 rounded-full bg-gray-200/10 blur-xl"></div>
      </div>

      {/* Compass-inspired background elements */}
      <div ref={compassElementsRef} className="absolute inset-0 overflow-hidden pointer-events-none z-0">
        {/* Large compass rose in the background */}
        <div className="compass-rose absolute w-[800px] h-[800px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className={`absolute inset-0 rounded-full border-2 ${isDarkMode ? 'border-gray-700/50' : 'border-gray-400/50'}`}></div>
          <div className={`absolute inset-[10%] rounded-full border-[1.5px] ${isDarkMode ? 'border-gray-700/40' : 'border-gray-400/40'}`}></div>
          <div className={`absolute inset-[20%] rounded-full border-[1.5px] ${isDarkMode ? 'border-gray-700/30' : 'border-gray-400/30'}`}></div>
          <div className={`absolute inset-[30%] rounded-full border ${isDarkMode ? 'border-gray-700/25' : 'border-gray-400/25'}`}></div>
          <div className={`absolute h-full w-[1.5px] left-1/2 top-0 ${isDarkMode ? 'bg-gray-600/40' : 'bg-gray-500/40'}`}></div>
          <div className={`absolute w-full h-[1.5px] top-1/2 left-0 ${isDarkMode ? 'bg-gray-600/40' : 'bg-gray-500/40'}`}></div>
          <div className={`absolute h-full w-[1px] left-1/2 top-0 rotate-45 origin-center ${isDarkMode ? 'bg-gray-600/35' : 'bg-gray-500/35'}`}></div>
          <div className={`absolute w-full h-[1px] top-1/2 left-0 rotate-45 origin-center ${isDarkMode ? 'bg-gray-600/35' : 'bg-gray-500/35'}`}></div>
        </div>

        {/* Medium compass elements */}
        <div className={`compass-circle absolute w-64 h-64 rounded-full top-1/4 right-[15%] border-2 ${isDarkMode ? 'border-yellow-500/30' : 'border-yellow-400/40'}`}></div>
        <div className={`compass-circle absolute w-48 h-48 rounded-full bottom-1/4 left-[10%] border-[1.5px] ${isDarkMode ? 'border-yellow-500/30' : 'border-yellow-400/40'}`}></div>
        
        {/* Compass direction markers */}
        <div className="compass-marker absolute top-[10%] left-1/2 transform -translate-x-1/2">
          <div className={`text-2xl font-bold ${isDarkMode ? 'text-yellow-500/40' : 'text-yellow-500/50'}`}>N</div>
        </div>
        <div className="compass-marker absolute bottom-[10%] left-1/2 transform -translate-x-1/2">
          <div className={`text-2xl font-bold ${isDarkMode ? 'text-yellow-500/40' : 'text-yellow-500/50'}`}>S</div>
        </div>
        <div className="compass-marker absolute left-[10%] top-1/2 transform -translate-y-1/2">
          <div className={`text-2xl font-bold ${isDarkMode ? 'text-yellow-500/40' : 'text-yellow-500/50'}`}>W</div>
        </div>
        <div className="compass-marker absolute right-[10%] top-1/2 transform -translate-y-1/2">
          <div className={`text-2xl font-bold ${isDarkMode ? 'text-yellow-500/40' : 'text-yellow-500/50'}`}>E</div>
        </div>

        {/* Small compass icons across the background */}
        <div className="compass-rose absolute w-16 h-16 top-[20%] left-[25%]">
          <div className={`w-full h-full rounded-full border-2 ${isDarkMode ? 'border-gray-600/50' : 'border-yellow-400/50'}`}>
            <div className={`absolute inset-0 flex items-center justify-center text-2xl ${isDarkMode ? 'text-yellow-500/40' : 'text-yellow-500/50'}`}>
              <FaCompass />
            </div>
          </div>
        </div>
        <div className="compass-rose absolute w-12 h-12 bottom-[30%] right-[20%]">
          <div className={`w-full h-full rounded-full border-2 ${isDarkMode ? 'border-gray-600/50' : 'border-yellow-400/50'}`}>
            <div className={`absolute inset-0 flex items-center justify-center text-xl ${isDarkMode ? 'text-yellow-500/40' : 'text-yellow-500/50'}`}>
              <FaCompass />
            </div>
          </div>
        </div>
        <div className="compass-rose absolute w-20 h-20 top-[70%] left-[15%]">
          <div className={`w-full h-full rounded-full border-2 ${isDarkMode ? 'border-gray-600/50' : 'border-yellow-400/50'}`}>
            <div className={`absolute inset-0 flex items-center justify-center text-2xl ${isDarkMode ? 'text-yellow-500/40' : 'text-yellow-500/50'}`}>
              <FaCompass />
            </div>
          </div>
        </div>
      </div>

      {/* Moving background elements - only visible in light mode */}
      {!isDarkMode && (
        <div ref={movingElementsRef} className="absolute inset-0 overflow-hidden pointer-events-none z-0">
          {/* Circle shapes */}
          <div className="moving-element absolute w-40 h-40 rounded-full bg-yellow-100/20 blur-xl"></div>
          <div className="moving-element absolute w-56 h-56 rounded-full bg-gray-200/25 blur-xl"></div>
          <div className="moving-element absolute w-32 h-32 rounded-full bg-yellow-50/20 blur-xl"></div>
          <div className="moving-element absolute w-48 h-48 rounded-full bg-gray-100/25 blur-xl"></div>
          
          {/* Square/rectangle shapes with rotation */}
          <div className="moving-element absolute w-24 h-24 bg-yellow-100/15 blur-xl rotate-45"></div>
          <div className="moving-element absolute w-36 h-36 bg-gray-200/20 blur-xl rotate-12"></div>
          <div className="moving-element absolute w-20 h-80 bg-yellow-50/15 blur-xl rotate-45"></div>
          
          {/* Pill shapes */}
          <div className="moving-element absolute w-72 h-20 rounded-full bg-gray-200/20 blur-xl"></div>
          <div className="moving-element absolute w-48 h-16 rounded-full bg-yellow-100/20 blur-xl"></div>
          <div className="moving-element absolute w-64 h-24 rounded-full bg-gray-100/25 blur-xl"></div>
          
          {/* Additional shapes for better visibility */}
          <div className="moving-element absolute w-20 h-20 bg-yellow-200/15 blur-lg rotate-45"></div>
          <div className="moving-element absolute w-48 h-16 rounded-full bg-gray-300/20 blur-xl"></div>
          <div className="moving-element absolute w-24 h-60 bg-yellow-100/20 blur-xl rotate-20"></div>
          <div className="moving-element absolute w-36 h-36 rounded-full bg-gray-100/20 blur-xl"></div>
        </div>
      )}

      <div className="container mx-auto px-4 md:px-8 z-10 pt-20 md:pt-0">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="order-2 md:order-1">
            {/* Add a semi-transparent background container around all text content */}
            <div className={`p-6 md:p-8 rounded-xl backdrop-blur-sm ${
              isDarkMode 
                ? 'bg-black/10 shadow-xl' 
                : 'bg-white/10 shadow-lg'
            }`}>
              <motion.h1
                className={`text-4xl md:text-5xl lg:text-6xl font-bold mb-6 ${isDarkMode ? 'text-white' : 'text-black'}`}
                variants={titleVariants}
                initial="hidden"
                animate="visible"
              >
              AB Consultancy: Shaping Future Leaders & Organizations
              </motion.h1>
              
              <motion.p
                className="text-xl mb-10"
                style={{ color: isDarkMode ? '#ffffff' : '#000000' }}
                variants={subtitleVariants}
                initial="hidden"
                animate="visible"
              >
                Welcome to AB Consultancy, where we turn potential into success. As experts in educational consulting, corporate training, and mentorship, we provide tailored solutions designed to cultivate tomorrow's entrepreneurs, leaders, and decision-makers.
              </motion.p>
              
              <div className="flex flex-wrap gap-4">
                <motion.a
                  href="#contact"
                  className="relative px-6 py-3 font-medium transition-all duration-300 rounded-md shadow-lg bg-yellow-400 hover:bg-yellow-500 text-black"
                  variants={buttonVariants}
                  initial="hidden"
                  animate="visible"
                  whileHover={{ 
                    scale: 1.03,
                    boxShadow: "0px 0px 20px rgba(234, 179, 8, 0.4)"
                  }}
                  whileTap={{ scale: 0.98 }}
                >
                  <span className="flex items-center justify-center">
                    Get Started
                    <svg 
                      className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </span>
                </motion.a>
                
                <motion.a
                  href="#services"
                  className={`px-6 py-3 font-medium border rounded-md transition-all duration-300 ${
                    isDarkMode 
                      ? 'border-white text-white hover:border-yellow-400 hover:text-yellow-400' 
                      : 'border-black text-black hover:border-yellow-500 hover:text-yellow-500'
                  }`}
                  variants={buttonVariants}
                  initial="hidden"
                  animate="visible"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Explore Services
                </motion.a>
              </div>
              
              <div className="mt-12 grid grid-cols-3 gap-6">
                {[
                  { value: "100+", label: "Training Programs" },
                  { value: "15+", label: "Partners & Global Network" },
                  { value: "95%", label: "Satisfaction Rate" },
                ].map((stat, i) => (
                  <motion.div
                    key={i}
                    custom={i}
                    variants={statsVariants}
                    initial="hidden"
                    animate="visible"
                    className="text-center"
                  >
                    <div className={`text-2xl md:text-3xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
                      {stat.value}
                    </div>
                    <div className="text-sm" style={{ color: isDarkMode ? '#cccccc' : '#333333' }}>
                      {stat.label}
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
          
          <div className="order-1 md:order-2 relative">
            <motion.div
              initial={{ opacity: 0, scale: 0.8, rotateY: 30 }}
              animate={{ opacity: 1, scale: 1, rotateY: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="relative z-10"
            >
              <div className={`relative w-full h-96 rounded-2xl p-1 shadow-2xl ${
                isDarkMode 
                  ? 'bg-gradient-to-br from-gray-800/20 to-gray-900/20' 
                  : 'bg-gradient-to-br from-gray-200/50 to-gray-300/50'
              }`}>
                <div className={`absolute inset-0 rounded-2xl blur-md ${
                  isDarkMode 
                    ? 'bg-gradient-to-br from-gray-800/30 to-gray-900/30' 
                    : 'bg-gradient-to-br from-gray-200/60 to-gray-300/60'
                }`}></div>
                
                {/* Content inside card */}
                <div className={`relative h-full w-full rounded-xl overflow-hidden ${
                  isDarkMode ? 'bg-black/80' : 'bg-white/80'
                } backdrop-blur-sm p-6 flex flex-col justify-center items-center`}>
                  <div className={`w-20 h-20 rounded-full flex items-center justify-center mb-4 ${
                    isDarkMode ? 'bg-white/20' : 'bg-black/10'
                  }`}>
                    <motion.div
                      variants={compassIconVariants}
                      initial="initial"
                      animate="animate"
                      className="relative"
                    >
                      <FaCompass className="text-4xl text-yellow-400" />
                      <div className={`absolute w-1 h-1 rounded-full bg-red-500 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}></div>
                    </motion.div>
                  </div>
                  
                  <h3 className={`text-xl font-bold mb-2 ${
                    isDarkMode ? 'text-white' : 'text-black'
                  }`}>Your Journey Starts Here</h3>
                  
                  <p className={`text-center ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-700'
                  }`}>
                    We provide the guidance, tools, and expertise to help you navigate your path to success.
                  </p>
                  
                  {/* <div className="mt-6 w-full">
                    <div className={`h-2 w-full rounded-full ${
                      isDarkMode ? 'bg-gray-800' : 'bg-gray-200'
                    } overflow-hidden`}>
                      <motion.div 
                        className={`h-full ${isDarkMode ? 'bg-white hover:bg-yellow-400' : 'bg-black hover:bg-yellow-500'} transition-colors duration-300`}
                        initial={{ width: 0 }}
                        animate={{ width: '85%' }}
                        transition={{ duration: 1, delay: 1 }}
                      ></motion.div>
                    </div>
                    <div className="flex justify-between mt-2">
                      <span className={`text-xs ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-600'
                      }`}>Start</span>
                      <span className={`text-xs font-medium ${isDarkMode ? 'text-white' : 'text-black'}`}>
                        85% Complete
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero; 