import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaLightbulb, FaHandshake, FaChartLine, FaRocket } from 'react-icons/fa';
import { ThemeContext } from '../context/ThemeContext';

const About = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const sectionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const valueItems = [
    {
      icon: <FaLightbulb className="text-yellow-400 text-3xl" />,
      title: "Future-Focused",
      description: "We continuously track global market needs to advise on study areas and qualifications that will be most in demand."
    },
    {
      icon: <FaHandshake className="text-yellow-400 text-3xl" />,
      title: "Partnership-Oriented",
      description: "We collaborate with businesses to develop tomorrow's workforce and leaders."
    },
    {
      icon: <FaChartLine className="text-yellow-400 text-3xl" />,
      title: "Client-Centered",
      description: "We work collaboratively with clients to help them reach their maximum potential."
    },
    {
      icon: <FaRocket className="text-yellow-400 text-3xl" />,
      title: "Results-Driven",
      description: "Whether enabling strategic goals, developing talent, or preparing leaders, we deliver on our promises."
    },
  ];

  return (
    <section 
      id="about" 
      className={`section-padding animated-bg ${
        isDarkMode ? 'bg-black' : 'bg-white'
      }`}
    >
      <motion.div className="container mx-auto">
        {/* <h2 className="heading-secondary mb-4">About AB Consultancy</h2>
        <p className="text-lg text-gray-300">
          We are a premier consulting firm that helps businesses navigate transformation and optimize operations.
        </p> */}
        <motion.div
          ref={ref}
          className="container mx-auto"
          variants={sectionVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
        >
          <motion.div className="text-center max-w-3xl mx-auto mb-16" variants={itemVariants}>
            <h2 className={`text-3xl md:text-4xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>About AB Consultancy</h2>
            <p className={`text-lg ${isDarkMode ? 'text-gray-300' : 'text-black'}`}>
              We are a premier consulting firm that helps businesses navigate transformation, 
              optimize operations, and accelerate growth through strategic innovation and digital expertise.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mb-20">
            <motion.div variants={itemVariants}>
              <h3 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>Our Vision</h3>
              <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-black'}`}>
                Our mission and vision are the compass that guides every program we develop and every consultation we provide. We believe in creating scalable, impactful ventures that not only succeed in today's environment but are positioned to thrive in tomorrow's landscape.
              </p>
              <p className={`${isDarkMode ? 'text-gray-300' : 'text-black'}`}>
                By aligning our services with future development trends, we help our clients stay ahead of the curve and capitalize on emerging opportunities in an increasingly competitive global marketplace.
              </p>
            </motion.div>

            <motion.div 
              variants={itemVariants}
              className="relative"
            >
              <motion.div
                initial={{ x: 0, y: 0, scale: 1 }}
                animate={{ 
                  x: [0, -5, 5, -5, 0],
                  y: [0, -5, 5, -5, 0],
                  scale: [1, 1.01, 0.99, 1.01, 1]
                }}
                transition={{
                  duration: 10,
                  repeat: Infinity,
                  repeatType: "mirror"
                }}
                className="relative z-10"
              >
                <div className="relative h-72 rounded-xl bg-gradient-to-r from-gray-500/20 to-gray-600/20 shadow-lg overflow-hidden">
                  <div className="absolute inset-0 bg-black/40 backdrop-blur-sm"></div>
                  <div className="absolute inset-0 flex flex-col justify-center items-center text-center p-8">
                    <h3 className={`text-3xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-white'}`}>
                    60+ years of collective experience
                    </h3>
                    <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-300'}`}>
                      Our dynamic network unites industry experts with over 60 years of collective experience, delivering programs that blend academic rigor with real-world insights for solutions that are both intellectually sound and commercially impactful.
                    </p>
                  </div>
                </div>
              </motion.div>
              
              {/* Background decorative elements */}
              <div className="absolute -top-5 -left-5 w-20 h-20 rounded-full bg-gray-500/10 animate-pulse-slow"></div>
              <div className="absolute -bottom-5 -right-5 w-16 h-16 rounded-full bg-gray-500/10 animate-float"></div>
            </motion.div>
          </div>

          <motion.h3 
            className={`text-4xl font-semibold mb-8 text-center ${isDarkMode ? 'text-white' : 'text-black'}`}
            variants={itemVariants}
          >
            What Makes Us Different
          </motion.h3>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {valueItems.map((item, index) => (
              <motion.div
                key={index}
                className={`card group hover:bg-gradient-to-b ${
                  isDarkMode 
                    ? 'hover:from-gray-900 hover:to-gray-800' 
                    : 'hover:from-gray-50 hover:to-white hover:shadow-lg'
                }`}
                variants={itemVariants}
                whileHover={{ 
                  y: -5, 
                  transition: { duration: 0.2 } 
                }}
              >
                <div className="flex flex-col items-center text-center">
                  <motion.div 
                    className={`mb-4 p-4 rounded-full ${
                      isDarkMode 
                        ? 'bg-gray-800 group-hover:bg-gray-700' 
                        : 'bg-gray-100 group-hover:bg-gray-200'
                    } transition-all duration-300`}
                    whileHover={{ rotate: [0, -10, 10, -10, 0], transition: { duration: 0.5 } }}
                  >
                    {item.icon}
                  </motion.div>
                  <h4 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-black'}`}>
                    {item.title}
                  </h4>
                  <p className={`${isDarkMode ? 'text-gray-400' : 'text-black'}`}>
                    {item.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default About; 