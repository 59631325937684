import React, { useState, useContext } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaWhatsapp, FaLinkedinIn, FaTwitter, FaFacebookF, FaInstagram, FaArrowRight } from 'react-icons/fa';
import { ThemeContext } from '../context/ThemeContext';

const Contact = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    service: '',
    message: '',
  });

  const [focus, setFocus] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const contactInfo = [
    {
      icon: <FaMapMarkerAlt />,
      title: 'Our Location',
      content: 'UAE 🇦🇪',
      url: ''
    },
    {
      icon: <FaPhone />,
      title: 'Call Us',
      content: '+971568322796',
      url: 'tel:+971568322796'
    },
    {
      icon: <FaWhatsapp />,
      title: 'WhatsApp',
      content: '+971 56 275 9328',
      url:"https://wa.me/971562759328"
    },
    {
      icon: <FaEnvelope />,
      title: 'Email',
      content: 'info@abconsultants.ae',
      url: "",
    },
  ];

  const socialMedia = [
    { icon: <FaLinkedinIn />, url: 'https://www.instagram.com/abconsultans?igsh=YXd2b3JqbjNkM2pz', label: 'LinkedIn' },
    { icon: <FaTwitter />, url: 'https://twitter.com', label: 'Twitter' },
    { icon: <FaFacebookF />, url: 'https://facebook.com', label: 'Facebook' },
    { icon: <FaInstagram />, url: 'https://www.instagram.com/abconsultans?igsh=YXd2b3JqbjNkM2pz', label: 'Instagram' },
  ];

  const serviceOptions = [
    'Educational Consultancy',
    'Corporate Training Programs',
    'Mentorship Services',
    'Leadership Development',
    'Startup Development Support',
    'Custom Program Design',
    'Other',
  ];

  const sectionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFocus = (field) => {
    setFocus(field);
  };

  const handleBlur = () => {
    setFocus(null);
  };

  const handleSubmit = (e) => {
    // This is now handled by FormSubmit directly
    // We just need to set the submitting state for the UI
    setSubmitting(true);
    
    // We'll set a timeout to reset the submitting state
    // in case the page doesn't redirect immediately
    setTimeout(() => {
      setSubmitting(false);
    }, 3000);
    
    // FormSubmit will handle the redirect after submission
  };

  return (
    <section 
      id="contact" 
      className={`section-padding relative overflow-hidden ${
        isDarkMode ? 'bg-black' : 'bg-white'
      }`}
    >
      {/* Decorative elements */}
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
        <div className={`absolute top-20 left-10 w-64 h-64 rounded-full ${isDarkMode ? 'bg-gray-800/10' : 'bg-gray-200/40'} blur-3xl`}></div>
        <div className={`absolute bottom-20 right-10 w-80 h-80 rounded-full ${isDarkMode ? 'bg-gray-800/10' : 'bg-gray-200/30'} blur-3xl`}></div>
      </div>

      <motion.div 
        ref={ref}
        className="container mx-auto relative z-10"
        variants={sectionVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <motion.div className="text-center max-w-3xl mx-auto mb-16" variants={itemVariants}>
          <h2 className={`text-3xl md:text-4xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>Get In Touch</h2>
          <p className={`text-lg ${isDarkMode ? 'text-gray-300' : 'text-black'}`}>
            Ready to transform your business? Contact us today to schedule a consultation.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-5 gap-12">
          <motion.div 
            className="lg:col-span-2" 
            variants={itemVariants}
          >
            <div className={`rounded-xl overflow-hidden p-8 h-full ${
              isDarkMode 
                ? 'bg-gray-900 shadow-xl' 
                : 'bg-gray-50 shadow-lg'
            }`}>
              <h3 className={`text-2xl font-semibold mb-6 ${
                isDarkMode ? 'text-white' : 'text-black'
              }`}>Contact Information</h3>
              
              <div className="space-y-6 mb-10">
                {contactInfo.map((item, index) => (
                  <motion.a
                    key={index}
                    href={item.url || ""}
                    className="flex items-start group"
                    initial={{ opacity: 0, x: -20 }}
                    animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -20 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    whileHover={{ x: 5 }}
                  >
                    <div className={`mt-1 w-12 h-12 rounded-full flex items-center justify-center text-white group-hover:scale-110 transition-all duration-300 ${
                      isDarkMode ? 'bg-gray-800' : 'bg-gray-800'
                    }`}>
                      {item.icon}
                    </div>
                    <div className="ml-4">
                      <h4 className={`text-lg font-medium ${
                        isDarkMode ? 'text-white' : 'text-black'
                      }`}>{item.title}</h4>
                      <p className={isDarkMode ? 'text-gray-300' : 'text-black'}>{item.content}</p>
                    </div>
                  </motion.a>
                ))}
              </div>

              <h3 className={`text-2xl font-semibold mb-4 ${
                isDarkMode ? 'text-white' : 'text-black'
              }`}>Connect With Us</h3>
              <div className="flex space-x-4 mb-10">
                {socialMedia.map((item, index) => (
                  <motion.a
                    key={index}
                    href={item.url}
                    target={item.url}
                    rel="noopener noreferrer"
                    className={`w-12 h-12 rounded-full flex items-center justify-center text-white hover:scale-110 transition-all ${
                      isDarkMode 
                        ? 'bg-gray-800 hover:bg-yellow-400 hover:text-black' 
                        : 'bg-gray-800 hover:bg-yellow-400 hover:text-black'
                    }`}
                    whileHover={{ y: -3 }}
                    aria-label={item.label}
                    initial={{ opacity: 0, y: 20 }}
                    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                    transition={{ duration: 0.5, delay: 0.6 + (index * 0.1) }}
                  >
                    {item.icon}
                  </motion.a>
                ))}
              </div>

              <motion.div
                className="relative rounded-lg overflow-hidden h-64 shadow-lg border-4 border-white"
                initial={{ opacity: 0, scale: 0.95 }}
                animate={inView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.5, delay: 0.7 }}
              >
                <iframe
                  title="Office Location"
                  className="absolute inset-0 w-full h-full"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57688.90051616845!2d55.45187184863279!3d25.40253309999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5765d6a5a699%3A0xada96868cfcfedad!2sAmbar%20Gem%20Tower!5e0!3m2!1sen!2sae!4v1678952431974!5m2!1sen!2sae"
                  loading="lazy"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </motion.div>
            </div>
          </motion.div>

          <motion.div 
            className="lg:col-span-3" 
            variants={itemVariants}
          >
            <div className={`rounded-xl p-8 shadow-lg h-full ${
              isDarkMode 
                ? 'bg-gray-900' 
                : 'bg-gray-50'
            }`}>
              <h3 className={`text-2xl font-semibold mb-6 ${
                isDarkMode ? 'text-white' : 'text-black'
              }`}>Send Us a Message</h3>
              
              <AnimatedContactForm
                formData={formData}
                handleChange={handleChange}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                focus={focus}
                submitting={submitting}
                submitSuccess={submitSuccess}
                serviceOptions={serviceOptions}
                isDarkMode={isDarkMode}
                errorMessage={errorMessage}
              />
            </div>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

const AnimatedContactForm = ({
  formData,
  handleChange,
  handleFocus,
  handleBlur,
  handleSubmit,
  focus,
  submitting,
  submitSuccess,
  serviceOptions,
  isDarkMode,
  errorMessage
}) => {
  return (
    <form 
      onSubmit={handleSubmit} 
      className="space-y-6"
      action="https://formsubmit.co/6c0982a3f496d6b25246f5053e1ae377" 
      method="POST"
    >
      {/* FormSubmit configuration */}
      <input type="hidden" name="_subject" value="New message from AB Consultancy Website!" />
      <input type="hidden" name="_captcha" value="false" />
      <input type="hidden" name="_template" value="table" />
      <input type="hidden" name="_next" value={window.location.href} />
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="relative">
          <motion.label
            htmlFor="name"
            className={`absolute left-3 transition-all duration-200 pointer-events-none ${
              focus === 'name' || formData.name
                ? `-top-2.5 text-xs ${isDarkMode ? 'text-yellow-400 bg-gray-900' : 'text-yellow-500 bg-gray-50'} px-1`
                : `top-3 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`
            }`}
            animate={
              focus === 'name' || formData.name
                ? { y: 0 }
                : { y: 0 }
            }
          >
            Your Name
          </motion.label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            onFocus={() => handleFocus('name')}
            onBlur={handleBlur}
            className={`w-full bg-transparent px-3 py-3 rounded-md border ${
              isDarkMode 
                ? 'border-gray-700 text-white focus:border-yellow-400' 
                : 'border-gray-300 text-black focus:border-yellow-500'
            } outline-none transition-colors`}
            required
          />
        </div>

        <div className="relative">
          <motion.label
            htmlFor="email"
            className={`absolute left-3 transition-all duration-200 pointer-events-none ${
              focus === 'email' || formData.email
                ? `-top-2.5 text-xs ${isDarkMode ? 'text-yellow-400 bg-gray-900' : 'text-yellow-500 bg-gray-50'} px-1`
                : `top-3 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`
            }`}
            animate={
              focus === 'email' || formData.email
                ? { y: 0 }
                : { y: 0 }
            }
          >
            Email Address
          </motion.label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            onFocus={() => handleFocus('email')}
            onBlur={handleBlur}
            className={`w-full bg-transparent px-3 py-3 rounded-md border ${
              isDarkMode 
                ? 'border-gray-700 text-white focus:border-yellow-400' 
                : 'border-gray-300 text-black focus:border-yellow-500'
            } outline-none transition-colors`}
            required
          />
        </div>

        <div className="relative">
          <motion.label
            htmlFor="phone"
            className={`absolute left-3 transition-all duration-200 pointer-events-none ${
              focus === 'phone' || formData.phone
                ? `-top-2.5 text-xs ${isDarkMode ? 'text-yellow-400 bg-gray-900' : 'text-yellow-500 bg-gray-50'} px-1`
                : `top-3 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`
            }`}
            animate={
              focus === 'phone' || formData.phone
                ? { y: 0 }
                : { y: 0 }
            }
          >
            Phone Number
          </motion.label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            onFocus={() => handleFocus('phone')}
            onBlur={handleBlur}
            className={`w-full bg-transparent px-3 py-3 rounded-md border ${
              isDarkMode 
                ? 'border-gray-700 text-white focus:border-yellow-400' 
                : 'border-gray-300 text-black focus:border-yellow-500'
            } outline-none transition-colors`}
            required
          />
        </div>

        <div className="relative">
          <motion.label
            htmlFor="company"
            className={`absolute left-3 transition-all duration-200 pointer-events-none ${
              focus === 'company' || formData.company
                ? `-top-2.5 text-xs ${isDarkMode ? 'text-yellow-400 bg-gray-900' : 'text-yellow-500 bg-gray-50'} px-1`
                : `top-3 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`
            }`}
            animate={
              focus === 'company' || formData.company
                ? { y: 0 }
                : { y: 0 }
            }
          >
            Company
          </motion.label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            onFocus={() => handleFocus('company')}
            onBlur={handleBlur}
            className={`w-full bg-transparent px-3 py-3 rounded-md border ${
              isDarkMode 
                ? 'border-gray-700 text-white focus:border-yellow-400' 
                : 'border-gray-300 text-black focus:border-yellow-500'
            } outline-none transition-colors`}
            required
          />
        </div>

        <div className="relative">
          <motion.label
            htmlFor="service"
            className={`absolute left-3 transition-all duration-200 pointer-events-none ${
              focus === 'service' || formData.service
                ? `-top-2.5 text-xs ${isDarkMode ? 'text-yellow-400 bg-gray-900' : 'text-yellow-500 bg-gray-50'} px-1`
                : `top-3 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`
            }`}
            animate={
              focus === 'service' || formData.service
                ? { y: 0 }
                : { y: 0 }
            }
          >
            {/* Service */}
          </motion.label>
          <select
            id="service"
            name="service"
            value={formData.service}
            onChange={handleChange}
            onFocus={() => handleFocus('service')}
            onBlur={handleBlur}
            className={`w-full bg-transparent px-3 py-3 rounded-md border ${
              isDarkMode 
                ? 'border-gray-700 text-white focus:border-yellow-400' 
                : 'border-gray-300 text-black focus:border-yellow-500'
            } outline-none transition-colors`}
            required
          >
            <option value="">Select a service</option>
            {serviceOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </div>

        <div className="relative">
          <motion.label
            htmlFor="message"
            className={`absolute left-3 transition-all duration-200 pointer-events-none ${
              focus === 'message' || formData.message
                ? `-top-2.5 text-xs ${isDarkMode ? 'text-yellow-400 bg-gray-900' : 'text-yellow-500 bg-gray-50'} px-1`
                : `top-3 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`
            }`}
            animate={
              focus === 'message' || formData.message
                ? { y: 0 }
                : { y: 0 }
            }
          >
            Message
          </motion.label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            onFocus={() => handleFocus('message')}
            onBlur={handleBlur}
            className={`w-full bg-transparent px-3 py-3 rounded-md border ${
              isDarkMode 
                ? 'border-gray-700 text-white focus:border-yellow-400' 
                : 'border-gray-300 text-black focus:border-yellow-500'
            } outline-none transition-colors`}
            required
          ></textarea>
        </div>
      </div>

      <motion.button
        type="submit"
        className={`px-6 py-3 rounded-md flex items-center justify-center w-full md:w-auto bg-yellow-400 text-black hover:bg-yellow-500 transition-colors duration-300`}
        disabled={submitting}
        whileHover={{ x: 5 }}
        whileTap={{ scale: 0.98 }}
      >
        {submitting ? (
          <span className="flex items-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
          </span>
        ) : (
          <span className="flex items-center">
            Send Message <FaArrowRight className="ml-2" />
          </span>
        )}
      </motion.button>

      {submitSuccess && (
        <motion.div
          className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded mt-4"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <p className="font-medium">Message sent successfully!</p>
          <p className="text-sm">Thank you for contacting us. We'll get back to you shortly.</p>
        </motion.div>
      )}

      {errorMessage && (
        <motion.div
          className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded mt-4"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <p className="font-medium">{errorMessage}</p>
        </motion.div>
      )}
    </form>
  );
};

export default Contact;