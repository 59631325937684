import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { 
  FaBriefcase, 
  FaChartBar, 
  FaCogs, 
  FaLaptopCode, 
  FaUserTie, 
  FaBullseye,
  FaArrowRight,
  FaGraduationCap,
  FaChalkboardTeacher,
  FaUsers,
  FaRocket
} from 'react-icons/fa';
import { ThemeContext } from '../context/ThemeContext';

const Services = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [activeService, setActiveService] = useState(null);
  const [servicesList, setServicesList] = useState([]);

  useEffect(() => {
    // Simulate fetching data
    const fetchData = async () => {
      // Replace this with your actual data fetching logic
      const data = [
        {
          id: 1,
          icon: <FaGraduationCap />,
          title: "Educational Consultancy",
          description: "Comprehensive consultancy services for educational institutions.",
        },
        {
          id: 2,
          icon: <FaChalkboardTeacher />,
          title: "Custom Training Programs",
          description: "Bespoke training solutions for organizations.",
        },
        {
          id: 3,
          icon: <FaUsers />,
          title: "Mentorship & Guidance",
          description: "Empowering students and aspiring entrepreneurs.",
        },
      ];
      setServicesList(data);
    };

    fetchData();
  }, []);

  // Check if servicesList is defined and is an array
  if (!Array.isArray(servicesList) || servicesList.length === 0) {
    return <div>Loading services...</div>; // Handle loading state
  }

  const sectionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const headerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    hover: {
      y: -10,
      boxShadow: "0px 10px 30px rgba(59, 130, 246, 0.2)",
      transition: {
        duration: 0.3,
      },
    },
  };

  const expandVariants = {
    closed: { height: 0, opacity: 0 },
    open: { height: "auto", opacity: 1 }
  };

  const handleServiceClick = (id) => {
    if (activeService === id) {
      setActiveService(null);
    } else {
      setActiveService(id);
    }
  };

  return (
    <section 
      id="services" 
      className={`section-padding ${
        isDarkMode ? 'bg-black' : 'bg-white'
      }`}
    >
      {/* Background decorative elements */}
      <div className="absolute top-20 left-10 w-64 h-64 bg-blue-500/5 rounded-full blur-3xl"></div>
      <div className="absolute bottom-20 right-10 w-80 h-80 bg-purple-500/5 rounded-full blur-3xl"></div>
      
      <motion.div
        ref={ref}
        className="container mx-auto"
        variants={sectionVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <motion.div className="text-center max-w-3xl mx-auto mb-16" variants={headerVariants}>
          <h2 className={`text-3xl md:text-4xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>Our Services</h2>
          <p className={isDarkMode ? 'text-white' : 'text-black'}>
            We offer a comprehensive range of services designed to transform educational 
            institutions and elevate corporate training through innovative approaches and 
            proven methodologies.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {servicesList.map((service, index) => (
            <motion.div
              key={index}
              variants={cardVariants}
              custom={index}
              className={`rounded-lg overflow-hidden group ${
                isDarkMode 
                  ? 'bg-gray-900 hover:bg-gray-800 border border-gray-800' 
                  : 'bg-white hover:bg-gray-50 shadow-lg border border-gray-100'
              }`}
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
            >
              <div className="p-8">
                <div 
                  className={`w-16 h-16 rounded-full mb-6 flex items-center justify-center text-2xl ${
                    isDarkMode 
                      ? 'bg-white/10 text-white hover:text-yellow-400' 
                      : 'bg-black/5 text-black hover:text-yellow-500'
                  } transition-colors duration-300`}
                >
                  {service.icon}
                </div>
                <h3 className={`text-xl font-bold mb-4 ${
                  isDarkMode ? 'text-white' : 'text-black'
                }`}>
                  {service.title}
                </h3>
                <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {service.description}
                </p>
                <a 
                  href="#contact" 
                  className={`inline-block mt-2 font-medium transition-colors ${
                    isDarkMode ? 'text-white hover:text-yellow-400' : 'text-black hover:text-yellow-500'
                  }`}
                >
                  Learn more →
                </a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Services; 