import React, { useState, useEffect, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBars, FaTimes, FaSun, FaMoon, FaCompass } from 'react-icons/fa';
import logo from '../AB-logo.png'; // Update the path to your logo
import { ThemeContext } from '../context/ThemeContext';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const navItems = [
    { title: 'Home', href: '#home' },
    { title: 'About', href: '#about' },
    { title: 'Services', href: '#services' },
    { title: 'Programs', href: '#portfolio' },
    { title: 'Partnerships', href: '#partnerships' },
    // { title: 'Team', href: '#team' },
    // { title: 'Testimonials', href: '#testimonials' },
    { title: 'Contact', href: '#contact' },
  ];

  const navVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.2,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  const buttonVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: 0.6,
      },
    },
    hover: {
      scale: 1.03,
      boxShadow: "0px 0px 20px rgba(59, 130, 246, 0.4)",
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };

  const mobileMenuVariants = {
    closed: { opacity: 0, x: "100%" },
    open: { opacity: 1, x: 0 },
  };

  const headerBgClass = isDarkMode 
    ? (scrolled ? 'bg-black bg-opacity-90' : 'bg-transparent')
    : (scrolled ? 'bg-white/90' : 'bg-transparent');

  return (
    <header 
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        scrolled ? 'py-2 backdrop-blur-sm shadow-lg' : 'py-4'
      } ${headerBgClass}`}
    >
      <div className="container mx-auto px-4 md:px-8">
        <nav className="flex justify-between items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-center"
          >
            <div className="flex items-center">
              <div className={`relative overflow-hidden ${isDarkMode ? 'bg-white p-1 rounded-lg' : ''}`}>
                <img 
                  src={logo} 
                  alt="AB Consultancy Logo" 
                  className={`${isDarkMode ? 'h-20 md:h-18' : 'h-20 md:h-18'} object-contain`} 
                />
              </div>
              {/* <span className={`ml-3 text-2xl font-bold hidden md:inline-block ${
                isDarkMode ? 'text-white' : 'text-black'
              }`}>
                Guiding Your Future
              </span> */}
            </div>
          </motion.div>

          {/* Desktop Menu */}
          <motion.ul
            className="hidden md:flex space-x-8 items-center"
            variants={navVariants}
            initial="hidden"
            animate="visible"
          >
            {navItems.map((item, index) => (
              <motion.li key={index} variants={itemVariants}>
                <a
                  href={item.href}
                  className="relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 after:bg-yellow-400 after:transition-all after:duration-300 hover:after:w-full"
                  style={{ color: isDarkMode ? '#ffffff' : '#000000' }}
                >
                  {item.title}
                </a>
              </motion.li>
            ))}
            
            {/* Theme Toggle */}
            <motion.li variants={itemVariants}>
              <button
                onClick={toggleTheme}
                className={`theme-toggle mr-4 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}`}
                aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
              >
                {isDarkMode ? (
                  <FaSun className="text-white hover:text-yellow-400 transition-colors duration-300 text-xl" />
                ) : (
                  <FaMoon className="text-black hover:text-yellow-500 transition-colors duration-300 text-xl" />
                )}
              </button>
            </motion.li>
            
            {/* <motion.li variants={itemVariants}>
              <motion.a 
                href="#contact"
                className="relative px-8 py-4 font-medium text-white text-lg transition-all duration-500 bg-gradient-to-br from-blue-600/80 to-purple-600/80 rounded-md shadow-xl group overflow-hidden"
                variants={buttonVariants}
                initial="hidden"
                animate="visible"
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0px 0px 25px rgba(59, 130, 246, 0.5)"
                }}
                whileTap={{ scale: 0.98 }}
              >
                <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-blue-400 to-purple-500 opacity-0 group-hover:opacity-30 transition-opacity duration-500"></span>
                <span className="absolute -inset-1 bg-gradient-to-r from-blue-400 via-purple-500 to-blue-500 rounded-lg blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-500 animate-pulse-slow"></span>
                <span className="relative z-10 flex items-center justify-center font-semibold">
                  Get Started
                  <svg 
                    className="w-6 h-6 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                  </svg>
                </span>
              </motion.a>
            </motion.li> */}
          </motion.ul>

          {/* Mobile Menu Button */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="md:hidden flex items-center"
          >
            <button
              onClick={toggleTheme}
              className={`theme-toggle mr-4 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}`}
              aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              {isDarkMode ? (
                <FaSun className="text-white hover:text-yellow-400 transition-colors duration-300 text-xl" />
              ) : (
                <FaMoon className="text-black hover:text-yellow-500 transition-colors duration-300 text-xl" />
              )}
            </button>
            
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="focus:outline-none"
              aria-label="Toggle menu"
              style={{ color: isDarkMode ? '#ffffff' : '#000000' }}
            >
              {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </motion.div>
        </nav>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={`fixed inset-0 z-40 flex flex-col md:hidden pt-24 ${
              isDarkMode ? 'bg-black bg-opacity-95' : 'bg-white bg-opacity-95'
            }`}
            variants={mobileMenuVariants}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <div className="flex flex-col items-center space-y-6 py-8">
              {navItems.map((item, index) => (
                <motion.a
                  key={index}
                  href={item.href}
                  className="text-xl"
                  style={{ color: isDarkMode ? '#ffffff' : '#000000' }}
                  onClick={() => setIsOpen(false)}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  {item.title}
                </motion.a>
              ))}
              <motion.a
                href="#contact"
                className="relative px-8 py-4 font-medium text-black text-lg transition-all duration-500 bg-yellow-400 hover:bg-yellow-500 border border-yellow-400 rounded-md shadow-xl group overflow-hidden mt-4"
                onClick={() => setIsOpen(false)}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: navItems.length * 0.1 }}
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0px 0px 25px rgba(234, 179, 8, 0.5)"
                }}
                whileTap={{ scale: 0.98 }}
              >
                <span className="relative z-10 flex items-center justify-center font-semibold">
                  Get Started
                  <svg 
                    className="w-6 h-6 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                  </svg>
                </span>
              </motion.a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header; 