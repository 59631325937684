import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaLinkedinIn, FaTwitter, FaEnvelope } from 'react-icons/fa';
import { ThemeContext } from '../context/ThemeContext';
import gmImage from '../assets/meetOurGM.jpeg';

const Team = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const sectionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  const generalManager = {
    id: 1,
    name: "Amr Bazeed",
    role: "General Manager",
    bio: "Our General Manager brings extensive experience in strategic leadership and operational excellence. With a proven track record of driving organizational growth and fostering innovation, he leads our team with vision and dedication to deliver exceptional results for our clients.",
    image: gmImage,
    specialties: ["Strategic Leadership", "Operational Management", "Business Development"],
    social: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      email: "mailto:gm@abconsulting.com"
    }
  };

  return (
    <section id="team" className={`section-padding ${isDarkMode ? 'bg-black' : 'bg-white'}`}>
      <motion.div
        ref={ref}
        className="container mx-auto"
        variants={sectionVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <motion.div className="text-center max-w-3xl mx-auto mb-16" variants={itemVariants}>
          <h2 className={`text-3xl md:text-4xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>Meet Our General Manager</h2>
          <p className={`text-lg ${isDarkMode ? 'text-gray-300' : 'text-black'}`}>
            Leading our team with vision and expertise to deliver exceptional results for our clients.
          </p>
        </motion.div>

        <div className="flex justify-center">
          <motion.div
            variants={itemVariants}
            className="group max-w-lg"
          >
            <div className="relative overflow-hidden rounded-t-xl">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                className="aspect-w-3 aspect-h-4"
              >
                <img
                  src={generalManager.image}
                  alt={generalManager.name}
                  className="w-full h-full object-cover object-center"
                />
              </motion.div>
              <div className="absolute inset-0 bg-gradient-to-t from-black via-black/40 to-transparent opacity-60 group-hover:opacity-40 transition-opacity duration-300"></div>
              
              {/* Social links that appear on hover */}
              {/* <div className="absolute bottom-0 inset-x-0 p-4 flex justify-center space-x-3 translate-y-10 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300">
                <motion.a
                  href={generalManager.social.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-9 h-9 rounded-full bg-yellow-400 flex items-center justify-center text-black hover:bg-yellow-500 transition-colors"
                  whileHover={{ y: -3 }}
                >
                  <FaLinkedinIn />
                </motion.a>
                <motion.a
                  href={generalManager.social.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-9 h-9 rounded-full bg-yellow-400 flex items-center justify-center text-black hover:bg-yellow-500 transition-colors"
                  whileHover={{ y: -3 }}
                >
                  <FaTwitter />
                </motion.a>
                <motion.a
                  href={generalManager.social.email}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-9 h-9 rounded-full bg-yellow-400 flex items-center justify-center text-black hover:bg-yellow-500 transition-colors"
                  whileHover={{ y: -3 }}
                >
                  <FaEnvelope />
                </motion.a>
              </div> */}
            </div>

            <div className={`p-6 rounded-b-xl ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50 shadow-md'}`}>
              <h3 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}>{generalManager.name}</h3>
              <div className={`${isDarkMode ? 'text-yellow-400' : 'text-yellow-500'} mb-3`}>{generalManager.role}</div>
              <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-700'} mb-4 text-sm`}>{generalManager.bio}</p>
              
              <div className="flex flex-wrap gap-2">
                {generalManager.specialties.map((specialty, idx) => (
                  <span 
                    key={idx} 
                    className={`px-2 py-1 text-xs ${
                      isDarkMode
                        ? 'bg-gray-800 text-white'
                        : 'bg-gray-200 text-black'
                    } rounded`}
                  >
                    {specialty}
                  </span>
                ))}
              </div>
            </div>
          </motion.div>
        </div>

        {/* <motion.div 
          className="text-center mt-16" 
          variants={itemVariants}
        >
          <p className={`text-lg ${isDarkMode ? 'text-gray-300' : 'text-black'} mb-6`}>
            Our leadership team is committed to delivering excellence in consulting and training services,
            helping organizations achieve their full potential.
          </p>
          <motion.a
            href="#contact"
            className="px-6 py-3 font-medium rounded-md transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg bg-yellow-400 text-black hover:bg-yellow-500 inline-block"
            whileHover={{ 
              scale: 1.05,
              boxShadow: "0px 5px 15px rgba(234, 179, 8, 0.3)"
            }}
            whileTap={{ scale: 0.95 }}
          >
            Work With Our Team
          </motion.a>
        </motion.div> */}
      </motion.div>
    </section>
  );
};

export default Team; 