import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { FaLinkedinIn, FaTwitter, FaFacebookF, FaInstagram, FaRegCopyright, FaChevronUp, FaMapMarkerAlt, FaPhone, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { ThemeContext } from '../context/ThemeContext';

const Footer = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const year = new Date().getFullYear();

  const servicesList = [
    { name: 'Educational Consultancy', url: '#services' },
    { name: 'Corporate Training', url: '#services' },
    { name: 'Mentorship Programs', url: '#services' },
    { name: 'Leadership Development', url: '#services' },
    { name: 'Startup Support', url: '#services' },
  ];

  const socialLinks = [
    { icon: <FaInstagram />, url: 'https://www.instagram.com/abconsultans?igsh=YXd2b3JqbjNkM2pz', name: 'Instagram' },
  ];

  const navLinks = [
    { name: 'Home', url: '#home' },
    { name: 'About', url: '#about' },
    { name: 'Services', url: '#services' },
    { name: 'Partnerships', url: '#partnerships' },
    { name: 'Team', url: '#team' },
    { name: 'Contact', url: '#contact' },
  ];

  const contactInfo = [
    { icon: <FaMapMarkerAlt />, info: 'UAE 🇦🇪' },
    { icon: <FaPhone />, info: '+971568322796' },
    { icon: <FaWhatsapp />, info: '+971 56 275 9328' },
    { icon: <FaEnvelope />, info: 'info@abconsultants.ae' },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  if (!servicesList) {
    console.log(servicesList);
    return <div>Loading...</div>;
  }

  return (
    <footer className={`pt-20 ${
      isDarkMode 
        ? 'bg-black text-gray-300' 
        : 'bg-white text-black'
    }`}>
      <div className="container mx-auto px-4 md:px-8">
        <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 mb-12">
          {/* Company Info */}
          <motion.div>
            <h3 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-black'
            }`}>AB Consultancy</h3>
            <p className="mb-6">
              Transforming potential into success through expert educational consulting and mentorship.
            </p>
            <div className="flex space-x-3">
              {socialLinks.map((link, index) => (
                <motion.a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`w-9 h-9 rounded-full flex items-center justify-center transition-colors ${
                    isDarkMode 
                      ? 'bg-gray-800 text-white hover:bg-yellow-400 hover:text-black' 
                      : 'bg-gray-200 text-black hover:bg-yellow-400'
                  }`}
                  whileHover={{ y: -3 }}
                  aria-label={link.name}
                >
                  {link.icon}
                </motion.a>
              ))}
            </div>
          </motion.div>

          {/* Services */}
          <motion.div>
            <h3 className={`text-xl font-semibold mb-6 ${
              isDarkMode ? 'text-white' : 'text-black'
            }`}>Our Services</h3>
            <ul className="space-y-3">
              {servicesList.map((service, index) => (
                <motion.li key={index}>
                  <a 
                    href={service.url} 
                    className={`transition-colors inline-block ${
                      isDarkMode 
                        ? 'hover:text-yellow-400' 
                        : 'hover:text-yellow-500'
                    }`}
                  >
                    {service.name}
                  </a>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          {/* Quick Links */}
          <motion.div>
            <h3 className={`text-xl font-semibold mb-6 ${
              isDarkMode ? 'text-white' : 'text-black'
            }`}>Quick Links</h3>
            <ul className="space-y-3">
              {navLinks.map((link, index) => (
                <motion.li key={index}>
                  <a 
                    href={link.url} 
                    className={`transition-colors inline-block ${
                      isDarkMode 
                        ? 'hover:text-yellow-400' 
                        : 'hover:text-yellow-500'
                    }`}
                  >
                    {link.name}
                  </a>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          {/* Contact Info */}
          <motion.div>
            <h3 className={`text-xl font-semibold mb-6 ${
              isDarkMode ? 'text-white' : 'text-black'
            }`}>Contact Us</h3>
            <ul className="space-y-3">
              {contactInfo.map((info, index) => (
                <motion.li key={index}>
                  <div className="flex items-start">
                    <div className={`mr-2 mt-1 ${
                      isDarkMode ? 'text-yellow-400' : 'text-yellow-500'
                    }`}>{info.icon}</div>
                    <p>{info.info}</p>
                  </div>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </motion.div>

        <motion.div className={`py-8 flex flex-col md:flex-row justify-between items-center ${
          isDarkMode ? 'border-t border-gray-800' : 'border-t border-gray-200'
        }`}>
          <div className="flex items-center mb-4 md:mb-0">
            <FaRegCopyright className="mr-2" />
            <p>{year} AB Consultancy. All Rights Reserved.</p>
          </div>
          
          <motion.button
            onClick={scrollToTop}
            className={`fixed bottom-8 right-8 w-10 h-10 rounded-full flex items-center justify-center shadow-lg z-50 ${
              isDarkMode 
                ? 'bg-yellow-400 text-black hover:bg-yellow-500' 
                : 'bg-yellow-400 text-black hover:bg-yellow-500'
            }`}
            whileHover={{ y: -2 }}
            whileTap={{ scale: 0.9 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <FaChevronUp />
          </motion.button>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer; 