import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ThemeContext } from '../context/ThemeContext';

// Import logo images - correct the paths
import logo1 from '../assets/1157526.jpg';
import logo2 from '../assets/Abu Dhabi Centre for Sheltering and Humanitarian Care.jpeg';
import logo3 from '../assets/Abu_Dhabi_National_Oil_Company-Logo.wine.png';
import logo4 from '../assets/BoMRA-____Logo-1.png';
import logo5 from '../assets/cropped-KTS-Logo_Colors.png';
import logo6 from '../assets/GDRFA.jpg';
// import logo7 from '../assets/Logo_page-0001 copy.png';
// import logo8 from '../assets/Logo_page-0001.jpg';
import logo9 from '../assets/Mission-Seal-HQ.png';
import logo10 from '../assets/MOI logo.jpg';
import logo11 from '../assets/OrgLogo.png';
import logo12 from '../assets/sonicwall-logo.png';
import logo13 from '../assets/tahaluf.png';
// Import new logos
import unhcrLogo from '../assets/UNHCR logo.png';
import edgeLogo from '../assets/Edge.png';
import modLogo from '../assets/Ministry of Defence logo.jpg';
import dmLogo from '../assets/DmLogo-new.svg.png';
import PublicLogo from '../assets/PublicFundLogo.png';
const Partnerships = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const sectionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2,
      },
    },
  };

  const headerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };

  const logoVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5 }
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 }
    }
  };

  // Add new animations for cards
  const cardHoverVariants = {
    rest: { 
      scale: 1,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.3, ease: "easeInOut" }
    },
    hover: { 
      scale: 1.03,
      boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.15)",
      transition: { duration: 0.3, ease: "easeInOut" }
    }
  };

  // Logo data with categories
  const partnerLogos = [
    { id: 1, src: logo1, alt: "Partner Logo", category: "government" },
    { id: 2, src: logo2, alt: "Abu Dhabi Centre for Sheltering and Humanitarian Care", category: "government" },
    { id: 3, src: logo3, alt: "Abu Dhabi National Oil Company", category: "corporate" },
    { id: 4, src: logo4, alt: "BoMRA Logo", category: "government" },
    { id: 5, src: logo5, alt: "KTS Logo", category: "education" },
    { id: 6, src: logo6, alt: "GDRFA", category: "government" },
    { id: 7, src: logo9, alt: "Mission Seal", category: "government" },
    { id: 8, src: logo10, alt: "MOI Logo", category: "government" },
    { id: 9, src: logo11, alt: "Organization Logo", category: "corporate" },
    { id: 10, src: logo12, alt: "SonicWall Logo", category: "technology" },
    { id: 11, src: logo13, alt: "Tahaluf Logo", category: "education" },
    // Add new logos
    { id: 12, src: unhcrLogo, alt: "UNHCR Logo", category: "international" },
    { id: 13, src: edgeLogo, alt: "Edge Logo", category: "technology" },
    { id: 14, src: modLogo, alt: "Ministry of Defence Logo", category: "government" },
    { id: 15, src: dmLogo, alt: "Dubai Municipality Logo", category: "government" },
    { id: 16, src: PublicLogo, alt: "Public Investment Fund", category: "government" },
  ];

  return (
    <section 
      id="partnerships" 
      className={`section-padding ${
        isDarkMode ? 'bg-black' : 'bg-gray-50'
      }`}
    >
      <motion.div
        ref={ref}
        className="container mx-auto"
        variants={sectionVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <motion.div className="text-center max-w-3xl mx-auto mb-16" variants={headerVariants}>
          <h2 className={`text-3xl md:text-4xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>Partnerships & Global Networks</h2>
          <p className={`text-lg ${isDarkMode ? 'text-white' : 'text-black'}`}>
            We collaborate with leading organizations worldwide to deliver exceptional 
            consulting services and educational solutions.
          </p>
        </motion.div>

        <div className="mb-12">
          <div className="flex justify-center flex-wrap gap-4 mb-8">
            <motion.button
              className={`px-4 py-2 rounded-md text-sm font-medium transition-all
                ${isDarkMode ? 'bg-white text-black hover:bg-yellow-400' : 'bg-black text-white hover:bg-yellow-500'}`}
              whileHover={{ y: -2 }}
              whileTap={{ y: 0 }}
            >
              All Partners
            </motion.button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-6">
            {partnerLogos.map((logo) => (
              <motion.div
                key={logo.id}
                variants={logoVariants}
                initial="rest"
                whileHover="hover"
                animate="visible"
                className="flex items-center justify-center"
              >
                <motion.div
                  variants={cardHoverVariants}
                  className={`flex items-center justify-center p-4 rounded-lg w-full aspect-[4/3] overflow-hidden ${
                    isDarkMode 
                      ? 'bg-white border border-gray-800 shadow-lg hover:border-yellow-400' 
                      : 'bg-white shadow-md hover:border border-yellow-500'
                  } transition-all duration-300`}
                >
                  <div className="relative flex items-center justify-center w-full h-full">
                    <img 
                      src={logo.src} 
                      alt={logo.alt} 
                      className="max-h-[80%] max-w-[80%] object-contain"
                    />
                  </div>
                </motion.div>
              </motion.div>
            ))}
          </div>
        </div>
        
        <motion.div 
          className="text-center mt-12"
          variants={headerVariants}
        >
          <p className={`${isDarkMode ? 'text-gray-300' : 'text-black'} max-w-2xl mx-auto`}>
            Our extensive network of partnerships enables us to provide comprehensive solutions 
            and create valuable opportunities for our clients across various sectors.
          </p>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Partnerships; 