import React, { useState, useEffect, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaArrowRight, FaSearch, FaUserTie, FaCogs, FaLightbulb } from 'react-icons/fa';
import { ThemeContext } from '../context/ThemeContext';

const Portfolio = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [filter, setFilter] = useState('all');
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const programsData = [
    {
      id: 1,
      title: "Re-Imagining Leadership",
      category: "leadership",
      image: "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
      icon: <FaUserTie className="text-yellow-400 text-xl" />,
      description: "Transform your leadership approach with innovative frameworks and practical strategies for the modern business landscape.",
      modules: [
        "Leadership in the Digital Era",
        "Strategic Vision Development",
        "Team Empowerment Techniques",
        "Sustainable Leadership Practices"
      ],
      level: "Executive"
    },
    {
      id: 2,
      title: "The Art of Business",
      category: "leadership",
      image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
      icon: <FaUserTie className="text-yellow-400 text-xl" />,
      description: "Master the delicate balance of creativity and strategic thinking required to excel in today's complex business environment.",
      modules: [
        "Business Model Innovation",
        "Creative Problem Solving",
        "Aesthetic Intelligence in Business",
        "Building a Culture of Innovation"
      ],
      level: "Senior Management"
    },
    {
      id: 3,
      title: "Crisis Leadership & L.E.A.D.E.R.S.H.I.P Values",
      category: "leadership",
      image: "https://images.unsplash.com/photo-1584438784894-089d6a62b8fa?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
      icon: <FaUserTie className="text-yellow-400 text-xl" />,
      description: "Develop the skills and mindset needed to navigate organizations through uncertainty and transformation with confidence.",
      modules: [
        "Crisis Assessment & Response",
        "Ethical Decision Making Under Pressure",
        "Resilience Building Strategies",
        "Stakeholder Communication in Crisis"
      ],
      level: "All Management Levels"
    },
    {
      id: 4,
      title: "Performance & Time Management",
      category: "management",
      image: "https://images.unsplash.com/photo-1506784365847-bbad939e9335?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
      icon: <FaCogs className="text-yellow-400 text-xl" />,
      description: "Optimize personal and team productivity through advanced performance management techniques and time optimization strategies.",
      modules: [
        "Strategic Goal Setting & KPIs",
        "Time Blocking Methodologies",
        "Priority Management Systems",
        "Performance Review Frameworks"
      ],
      level: "Mid-Management"
    },
    {
      id: 5,
      title: "Change Management",
      category: "management",
      image: "https://images.unsplash.com/photo-1511497584788-876760111969?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
      icon: <FaCogs className="text-yellow-400 text-xl" />,
      description: "Learn systematic approaches to facilitate organizational transformations while minimizing resistance and maximizing adoption.",
      modules: [
        "Change Readiness Assessment",
        "Stakeholder Mapping & Engagement",
        "Communication Strategies for Change",
        "Measuring Change Success"
      ],
      level: "Senior Management"
    },
    {
      id: 6,
      title: "Emotional Intelligence Diploma",
      category: "management",
      image: "https://images.unsplash.com/photo-1526378722484-bd91ca387e72?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
      icon: <FaCogs className="text-yellow-400 text-xl" />,
      description: "Develop advanced emotional intelligence capabilities to enhance leadership effectiveness and team dynamics.",
      modules: [
        "Self-Awareness & Regulation",
        "Empathy Development",
        "Conflict Resolution Through EQ",
        "Building Emotionally Intelligent Teams"
      ],
      level: "All Levels"
    },
    {
      id: 7,
      title: "Young Entrepreneurial Summit",
      category: "specialized",
      image: "https://images.unsplash.com/photo-1507679799987-c73779587ccf?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
      icon: <FaLightbulb className="text-yellow-400 text-xl" />,
      description: "Intensive program designed to equip young entrepreneurs with the mindset, skills, and connections needed to launch successful ventures.",
      modules: [
        "Idea Validation & Business Modeling",
        "Market Research & Customer Discovery",
        "Pitch Development & Delivery",
        "Startup Funding Strategies"
      ],
      level: "Entry Level"
    },
    {
      id: 8,
      title: "Teaching Pedagogy",
      category: "specialized",
      image: "https://images.unsplash.com/photo-1497633762265-9d179a990aa6?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
      icon: <FaLightbulb className="text-yellow-400 text-xl" />,
      description: "Advanced instructional methodologies for educators seeking to enhance learning outcomes through innovative teaching approaches.",
      modules: [
        "Student-Centered Learning Design",
        "Technology Integration in Education",
        "Assessment Strategy Development",
        "Inclusive Teaching Practices"
      ],
      level: "Professional"
    },
    {
      id: 9,
      title: "The Power of Communication",
      category: "specialized",
      image: "https://images.unsplash.com/photo-1563986768494-4dee2763ff3f?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
      icon: <FaLightbulb className="text-yellow-400 text-xl" />,
      description: "Master the art of effective communication across various contexts, enhancing personal influence and organizational clarity.",
      modules: [
        "Strategic Messaging Framework",
        "Public Speaking Mastery",
        "Cross-Cultural Communication",
        "Digital Communication Strategies"
      ],
      level: "All Levels"
    }
  ];

  useEffect(() => {
    setFilteredPrograms(
      searchQuery.trim() !== '' 
        ? programsData.filter(program => 
            program.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            program.description.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : filter === 'all'
        ? programsData
        : programsData.filter(program => program.category === filter)
    );
  }, [filter, searchQuery]);

  const sectionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const headerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  const programVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      y: 50,
      transition: {
        duration: 0.3,
      },
    },
  };

  const filters = [
    { id: 'all', label: 'All Programs' },
    { id: 'leadership', label: 'Leadership Development' },
    { id: 'management', label: 'Management Skills' },
    { id: 'specialized', label: 'Specialized Programs' },
  ];

  const openProgramDetails = (program) => {
    setSelectedProgram(program);
  };

  const closeProgramDetails = () => {
    setSelectedProgram(null);
  };

  const ProgramDetailsModal = ({ program, onClose }) => {
    if (!program) return null;

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/95 backdrop-blur-sm overflow-y-auto"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={`relative rounded-xl shadow-2xl max-w-4xl w-full overflow-hidden ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className="absolute top-4 right-4 text-gray-400 hover:text-white z-10 bg-black/50 p-2 rounded-full"
            onClick={onClose}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <div className="h-64 relative">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{ backgroundImage: `url(${program.image})` }}
            >
              <div className={`absolute inset-0 bg-gradient-to-t ${isDarkMode ? 'from-gray-900 via-gray-900/70' : 'from-white via-white/70'} to-transparent`}></div>
            </div>
          </div>

          <div className={`p-8 -mt-16 relative ${isDarkMode ? 'text-white' : 'text-black'}`}>
            <span className="px-4 py-1 rounded-full bg-yellow-400/20 text-yellow-400 text-sm font-medium">
              {filters.find(f => f.id === program.category)?.label}
            </span>
            <div className="flex items-center mt-4">
              <div className="mr-3">{program.icon}</div>
              <h3 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>{program.title}</h3>
            </div>
            <div className="flex space-x-4 mt-2 text-sm">
              <p className={`${isDarkMode ? 'text-yellow-400' : 'text-yellow-600'}`}>Level: {program.level}</p>
            </div>

            <div className="space-y-6 mt-6">
              <div>
                <h4 className={`text-lg font-semibold ${isDarkMode ? 'text-yellow-400' : 'text-yellow-600'} mb-2`}>Overview</h4>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>{program.description}</p>
              </div>

              <div>
                <h4 className={`text-lg font-semibold ${isDarkMode ? 'text-yellow-400' : 'text-yellow-600'} mb-2`}>Program Modules</h4>
                <ul className="space-y-2">
                  {program.modules.map((module, idx) => (
                    <li key={idx} className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'} flex items-start`}>
                      <span className="text-yellow-400 mr-2 mt-1">■</span> {module}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="mt-8 flex justify-end">
              <button
                className="px-6 py-2 bg-yellow-400 text-black rounded-md hover:bg-yellow-500 transition-colors"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    );
  };

  return (
    <section id="portfolio" className={`section-padding ${isDarkMode ? 'bg-black' : 'bg-white'}`}>
      <motion.div
        ref={ref}
        className="container mx-auto"
        variants={sectionVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <motion.div className="text-center max-w-3xl mx-auto mb-16" variants={headerVariants}>
          <h2 className={`text-3xl md:text-4xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>
            Sample Training Programs
          </h2>
          <p className={`text-lg ${isDarkMode ? 'text-white' : 'text-black'}`}>
            Our extensive catalog of over 100+ training programs covers essential business 
            competencies across multiple domains. Each program can be customized to meet your 
            organization's specific needs.
          </p>
        </motion.div>

        <div className="flex flex-col md:flex-row justify-between items-center mb-8 space-y-4 md:space-y-0">
          <div className="flex flex-wrap gap-2">
            {filters.map((item) => (
              <motion.button
                key={item.id}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-all ${
                  filter === item.id 
                    ? 'bg-yellow-400 text-black' 
                    : isDarkMode 
                      ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => setFilter(item.id)}
                whileHover={{ y: -2 }}
                whileTap={{ y: 0 }}
              >
                {item.label}
              </motion.button>
            ))}
          </div>

          <div className="relative">
            <input
              type="text"
              placeholder="Search programs..."
              className={`${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 text-gray-300 focus:ring-yellow-400/40' 
                  : 'bg-gray-100 border-gray-300 text-gray-700 focus:ring-yellow-500/40'
              } border rounded-md py-2 pl-10 pr-4 w-64 focus:outline-none focus:ring-2`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <FaSearch className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`} />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <AnimatePresence>
            {filteredPrograms.map((program) => (
              <motion.div
                key={program.id}
                layout
                variants={programVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={`group relative overflow-hidden rounded-xl transition-all duration-300 ${
                  isDarkMode 
                    ? 'bg-gray-900 hover:shadow-[0_0_15px_rgba(250,204,21,0.3)]' 
                    : 'bg-white hover:shadow-xl border border-gray-200'
                }`}
              >
                <div className="relative h-48 overflow-hidden">
                  <motion.div
                    whileHover={{
                      scale: 1.05,
                      transition: { duration: 0.3 },
                    }}
                    className="h-full w-full"
                  >
                    <div
                      className="w-full h-full bg-cover bg-center transition-transform duration-500"
                      style={{ backgroundImage: `url(${program.image})` }}
                    />
                  </motion.div>
                  <div className={`absolute inset-0 bg-gradient-to-t ${isDarkMode ? 'from-gray-900' : 'from-white'} via-transparent to-transparent`}></div>
                </div>

                <div className="p-6">
                  <span className="px-3 py-1 text-xs font-medium rounded-full bg-yellow-400/20 text-yellow-400">
                    {filters.find(f => f.id === program.category)?.label}
                  </span>
                  <div className="flex items-center mt-3">
                    <div className="mr-2">{program.icon}</div>
                    <h3 className={`text-xl font-semibold ${
                      isDarkMode 
                        ? 'text-white group-hover:text-yellow-400' 
                        : 'text-gray-900 group-hover:text-yellow-600'
                    } transition-colors`}>
                      {program.title}
                    </h3>
                  </div>
                  <p className={`text-sm my-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-700'}`}>
                    {program.description.length > 120
                      ? `${program.description.substring(0, 120)}...`
                      : program.description}
                  </p>
                  <button
                    onClick={() => openProgramDetails(program)}
                    className={`flex items-center font-medium transition-colors group ${
                      isDarkMode ? 'text-yellow-400 hover:text-yellow-300' : 'text-yellow-600 hover:text-yellow-700'
                    }`}
                  >
                    View Program Details
                    <motion.span
                      initial={{ x: 0 }}
                      whileHover={{ x: 4 }}
                      transition={{ duration: 0.2 }}
                    >
                      <FaArrowRight className="ml-2" />
                    </motion.span>
                  </button>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>

        {filteredPrograms.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center py-16"
          >
            <p className={`text-xl ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              No programs found matching your criteria.
            </p>
            <button
              onClick={() => {
                setFilter('all');
                setSearchQuery('');
              }}
              className="mt-4 px-6 py-2 bg-yellow-400 text-black rounded-md hover:bg-yellow-500 transition-colors"
            >
              Reset Filters
            </button>
          </motion.div>
        )}

        <motion.div 
          variants={headerVariants} 
          className="text-center max-w-4xl mx-auto mt-16"
        >
          <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Our extensive catalog of over 100+ training programs covers essential business competencies across 
            multiple domains. From leadership development and management skills to specialized technical training, 
            we offer solutions for organizations at every level of maturity. Each program can be delivered as a 
            standalone module or integrated into a comprehensive multi-stage training initiative.
          </p>
        </motion.div>

        <AnimatePresence>
          {selectedProgram && (
            <ProgramDetailsModal
              program={selectedProgram}
              onClose={closeProgramDetails}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </section>
  );
};

export default Portfolio; 